import { BASEURL, portOnServer, domainName } from "../config";

export type Colors =
  | "gray"
  | "red"
  | "yellow"
  | "brown"
  | "black"
  | "orange"
  | "blue"
  | "green";

export const colorList: Colors[] = [
  "gray",
  "red",
  "yellow",
  "black",
  "brown",
  "orange",
  "green",
  "blue",
];

export const colorsToRussian = {
  gray: "Серый",
  red: "Красный",
  yellow: "Желтый",
  brown: "Коричневый",
  black: "Темно-серый",
  orange: "Оранжевый",
  blue: "Синий",
  green: "Зеленый",
};

interface PriceView {
  gray: number;
  red: number;
  yellow: number;
  black: number;
  brown: number;
  orange: number;
  green: number;
  blue: number;
}

interface ProductView {
  id: number;
  title: string;
  category: string;
  calculator_type: number;
  priority: number;
  price: PriceView;
}

interface ComponentsView {
  title: string;
  size: string;
  count_in_metr: number;
  weight: number;
  coef: number;
  product: ProductView;
}

interface ImageView {
  is_avatar: number;
  src: string;
  product: { id: number };
  priority: number;
}

export interface PagesAndPanel {
  id: number;
  title: string;
  is_active: string;
  is_page: string;
  is_panel: string;
  panel_content: string;
  page_content: string;
}

export interface IProductElement {
  title: string;
  size: string;
  count_in_metr: number;
  coef: number;
  weight: number;
}

export interface IProduct {
  id: number;
  title: string;
  category: string;
  is_squared_calculator: boolean;
  price: PriceView;
  priority: number;
  elements: IProductElement[];
}

export interface IImage {
  is_avatar: number;
  src: string;
  productId: number;
  priority: number;
}

interface DictProductType {
  [id: number]: IProduct;
}

export interface DictBoardImages {
  [id: number]: string; //id: src
}

function combineById(data: ComponentsView[]): IProduct[] {
  let result: IProduct[] = [];
  let dict: DictProductType = {};

  for (let i = 0; i < data.length; ++i) {
    const component: ComponentsView = data[i];
    const { product } = component;
    if (!dict[product.id]) {
      const { id, title, category, price, priority } = product;
      const productItem = {
        id,
        title,
        category,
        price,
        is_squared_calculator: product.calculator_type === 0,
        elements: [],
        priority: priority
      };
      dict[product.id] = productItem;
    }
    dict[product.id].elements.push({
      title: component.title,
      size: component.size,
      count_in_metr: component.count_in_metr,
      coef: component.coef,
      weight: component.weight,
    } as IProductElement);
  }

  //преобразование в список
  for (let id in dict) {
    result.push(dict[id]);
  }

  result.sort((a, b) => a.priority - b.priority)

  return result;
}

export function getProductsView(data: ComponentsView[]) {
  return combineById(data);
}

export function filterProductCtegory(products: IProduct[], category: string) {
  return products.filter((prod: IProduct) => prod.category == category);
}

export function getProductById(data: IProduct[], id: number) {
  const res = data.filter((prod) => prod.id === +id);
  return res.length > 0 ? res[0] : null;
}

export function getLowesPrices(prod: IProduct): number {
  let res = 0;
  if (prod.elements.length === 1) {
    res = prod.price.gray;
  } else {
    res =
      prod.price.gray * prod.elements[0].coef +
      prod.price.gray * prod.elements[1].coef;
  }
  return Math.ceil(res / 10) * 10;
}

export function getContacts(data: any[]): IContacts | null {
  if (!data[0]) {
    return null;
  }
  return data[0] as IContacts;
}

export function colorTranslate(color: string) {
  switch (color) {
    case "gray":
      return "серый";
    case "red":
      return "красный";
    case "yellow":
      return "желтый";
    case "brown":
      return "коричневый";
    case "blue":
      return "синий";
    case "green":
      return "зелёный";
    case "black":
      return "темно-серый";
    case "orange":
      return "оранжевый";
  }
  return color;
}

const imageComparator = (a: IImage, b: IImage) => {
  if (a.is_avatar === 1) return -1;
  if (b.is_avatar === 1) return 1;
  return a.priority - b.priority;
};

//Получение данных

export async function getProductImages(productId: number) {
  const response: ImageView[] = await fetch(
    `${BASEURL}/api/images/?product=${productId}`
  ).then((response) => response.json());
  const imageList: IImage[] = [];
  for (let image of response) {
    const { is_avatar, src, priority, product } = image as ImageView;
    const item: IImage = {
      is_avatar,
      src: fixUrl(src),
      priority,
      productId: product.id,
    };
    imageList.push(item);
  }

  imageList.sort(imageComparator);
  return imageList;
}

export async function getBoardImages() {
  const response: ImageView[] = await fetch(
    `${BASEURL}/api/images/?product=all`
  ).then((response) => response.json());
  const imageDict: DictBoardImages = {};
  for (let image of response) {
    imageDict[image.product.id] = fixUrl(image.src);
  }
  return imageDict;
}

export async function getGaleryImages() {
  const response: ImageView[] = await fetch(
    `${BASEURL}/api/images/`
  ).then((response) => response.json());
  const imageList: IImage[] = [];
  for (let image of response) {
    const { is_avatar, src, priority, product } = image as ImageView;
    const item: IImage = {
      is_avatar,
      src: fixUrl(src),
      priority,
      productId: product.id,
    };
    imageList.push(item);
  }

  imageList.sort(imageComparator);
  return imageList;
}

export function fixUrl(url: string | undefined) {
  //TODO: разобраться почему ломаются урлы на сервере
  if (!url) return "";
  return url.replace(`127.0.0.1:${portOnServer}`, domainName);
}

export async function getPagesAndPanel() {
  const response: PagesAndPanel[] = await fetch(
    `${BASEURL}/api/pages/`
  ).then((response) => response.json());
  return response;
}

export async function getPageById(id: number) {
  const response: PagesAndPanel = await fetch(
    `${BASEURL}/api/pages/${id}/`
  ).then((response) => response.json());
  return response;
}
