import React from "react";
import "./styles.scss";
import { BASEURL } from "../../config";
import { getContacts } from "../../utils/utils";
import whatsapp from "../../images/Whatsapp.png";
import { Helmet } from "react-helmet";

interface IState {
  contacts: IContacts | null;
}

export class Contacts extends React.Component {
  state: IState = {
    contacts: null,
  };

  componentDidMount() {
    fetch(`${BASEURL}/api/contacts/`)
      .then((response) => response.json())
      .then((result) => getContacts(result))
      .then((result) => this.setState({ ...this.state, contacts: result }))
      .catch((e) => console.log(e));
  }

  render() {
    const { contacts } = this.state;
    const width = document.body.clientWidth;
    const mapStyles =
      width < 900
        ? {
            height: `${(width - 20) * 0.9}px`,
            width: `${(width - 20) * 0.9}px`,
          }
        : {};
    return (
      <div className="contacts-container">
        <Helmet>
          <title>Наши контакты и адрес | ООО "Фантазия</title>
        </Helmet>
        <h2>Директор: Михайлов Дмитрий Степанович</h2>
        <h3>Наши контакты:</h3>

        <div className="vcard">
          <div>
            <span className="category">Тротуарная плитка </span>
            <span className="fn org">ООО "Фантазия"</span>
          </div>
          <br />
          <div>
            Телефоны:
            <br />
            <span className="tel">{contacts?.phone_1 || "+79173465860"}</span>
            <a href={`https://wa.me/${contacts?.phone_1}`}>
              <img src={whatsapp} alt="whatsapp" className={"whatsapp"} />
            </a>
            <br />
            <span className="tel">{contacts?.phone_2 || "+79632354874"}</span>
            <br />
            <span className="tel">{contacts?.phone_3 || "+7(347)2940039"}</span>
            <br />
          </div>
          <div>
            <span>{contacts?.email || "info@plitka-ufa.ru"}</span>
          </div>
          <br />
          <div className="adr">
            <span className="locality">Чишминский район, с. Сайраново</span>,
            <span className="street-address">ул. Родниковая, 29</span>
          </div>
          <div>
            <span className="url">
              <span className="value-title" title="http://www.plitka-ufa.ru">
                {" "}
              </span>
            </span>
          </div>
        </div>
        <br />
        <iframe
          className="map"
          title="Наше положение на карте"
          style={mapStyles}
          src="https://yandex.ru/map-widget/v1/?um=constructor%3Af2581239fd4d0c2c52b64498b0602fa5bb3e6d9394a6ee51aa6394f71c131ecc&amp;source=constructor"
        ></iframe>
      </div>
    );
  }
}
