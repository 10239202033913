import React from "react";
// import "./styles.scss";
import { Helmet } from "react-helmet";

export class NotFoundPage extends React.Component {
  render() {
    return (
      <div style={{ fontSize: "20px", fontWeight: 900 }}>
        <Helmet>
          <title>404 Страница не найдена | ООО "Фантазия</title>
        </Helmet>
        Ошибка 404. Страница не найдена
      </div>
    );
  }
}
