import React from "react";
import "./Calculator.scss";
import gray from "../../../images/colors/gray.png";
import red from "../../../images/colors/red.png";
import yellow from "../../../images/colors/yellow.png";
import brown from "../../../images/colors/brown.png";
import blue from "../../../images/colors/blue.png";
import green from "../../../images/colors/green.png";
import black from "../../../images/colors/black.png";
import orange from "../../../images/colors/orange.png";

import grayBig from "../../../images/colors/gray_big.png";
import redBig from "../../../images/colors/red_big.png";
import yellowBig from "../../../images/colors/yellow.png";
import brownBig from "../../../images/colors/brown.png";
import blueBig from "../../../images/colors/blue.png";
import greenBig from "../../../images/colors/green_big.png";
import blackBig from "../../../images/colors/black_big.png";
import orangeBig from "../../../images/colors/orange_big.png";

import {
  IProduct,
  colorList,
  colorsToRussian,
  Colors,
  IProductElement,
} from "../../../utils/utils";

interface CalculatorProps {
  product: IProduct;
  handleColor: (colors: Colors[]) => void;
  square: number;
  handleSquare: (e: any) => void;
  is_squared: boolean;
  price: number;
}

interface CalculatorState {
  activeColor: number[];
}

const SMALL_SRC = [gray, red, yellow, black, brown, orange, green, blue];
const BIG_SRC = [
  grayBig,
  redBig,
  yellowBig,
  blackBig,
  brownBig,
  orangeBig,
  greenBig,
  blueBig,
];

export class Calculator extends React.Component<CalculatorProps> {
  state: CalculatorState = {
    activeColor: [0, 0],
  };

  render() {
    const { product, square, handleSquare, is_squared } = this.props;
    const isClever = product.id === 2;
    return (
      <div className={"price-calculator-container"}>
        <h2>Калькулятор стоимости</h2>
        <div className={"price-calculator"}>
          <div className={"price-calculator-inner"}>
            {product.elements.map((elem, index) =>
              this.colorPicker(elem, index, isClever && index === 0)
            )}
          </div>

          <div className={"after-form"}>
            <div className={"text-input"}>
              <span className={"text-input_text"}>
                {is_squared ? "Площадь:" : "Количество:"}
              </span>
              <input
                type="number"
                value={square}
                min="0"
                onChange={(e) => handleSquare(e)}
              />
              <span className={"text-input_text"}>
                {is_squared ? (
                  <>
                    м<sup>2</sup>
                  </>
                ) : (
                  "шт"
                )}
              </span>
            </div>

            <div>
              <span>Цена </span>
              <span className={"calculator-price"}>{this.props.price}</span>
              <span> руб</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private colorPicker(
    elem: IProductElement,
    index: number,
    useBig: boolean = false
  ) {
    const { product } = this.props;
    const active = this.state.activeColor[index];
    const src = useBig ? BIG_SRC : SMALL_SRC;
    return (
      <div key={index} style={{ flexGrow: 1 }}>
        {product.elements.length > 1 && <h3>{elem.title}:</h3>}
        <div className={"price-calculator_color-picker"}>
          {colorList.map((color, i) => {
            const isActive = i == active;
            return (
              <span
                className={`color-samples-element ${
                  isActive ? "color-samples-element--active" : ""
                }`}
                key={color}
                onClick={() => this.clickColorHandler(index, i)}
              >
                <div className={"color-samples-element__title"}>
                  {colorsToRussian[color as Colors]}
                </div>
                <img
                  className={"color-samples-element__img"}
                  src={src[i]}
                  alt={`${
                    colorsToRussian[color as Colors]
                  } цвет тротуарной пликти`}
                />
              </span>
            );
          })}
        </div>
      </div>
    );
  }

  private clickColorHandler(elementIndex: number, colorIndex: number) {
    const activeColor = this.state.activeColor;
    activeColor[elementIndex] = colorIndex;
    this.setState({ activeColor });
    const colors = [colorList[activeColor[0]], colorList[activeColor[1]]];
    this.props.handleColor(colors);
  }
}
