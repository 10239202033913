import React from "react";
import "./styles.scss";
import noimage from "../../images/noimage.gif";
import { Link } from "react-router-dom";
import { BASEURL } from "../../config";
import {
  getProductsView,
  getLowesPrices,
  filterProductCtegory,
  IProduct,
  getBoardImages,
  DictBoardImages,
} from "../../utils/utils";
import { Helmet } from "react-helmet";

interface IState {
  products: IProduct[] | null;
  category: string | undefined;
  imageDict: DictBoardImages | null;
}

interface ProductBoardProps {
  match?: {
    params: {
      id: string;
    };
  };
}

export class ProductBoard extends React.Component<ProductBoardProps> {
  state: IState = {
    products: null,
    category: undefined,
    imageDict: null,
  };

  componentDidMount() {
    if (this.state.products && this.state.products.length > 0) {
      //@ts-ignore
      window.prerenderReady = true;
    }
  }

  loadData() {
    //@ts-ignore
    window.prerenderReady = false;
    const category = this.props.match?.params.id;
    fetch(`${BASEURL}/api/components/`)
      .then((response) => response.json())
      .then((result) => getProductsView(result))
      .then((result) =>
        category ? filterProductCtegory(result, category) : result
      )
      .then((result) => this.setState({ products: result, category }))
      .catch((e) => console.log(e));
    getBoardImages().then((imageDict: DictBoardImages) =>
      this.setState({ imageDict })
    );
  }

  render() {
    const category = this.props.match?.params.id;
    const { products } = this.state;
    if ((category && this.state.category != category) || !products) {
      this.loadData();
    }
    return (
      <div className={"plitka-board"}>
        <Helmet>
          <title>
            Каталог тротуарной плитки в Уфе | ООО "Фантазия"
          </title>
          <meta
            name="description"
            content={`Каталог тротуарной плитки в Уфе от производителя. Самая качественная продукция с низкими ценами`}
          />
        </Helmet>
        <h1>Каталог тротуарной плитки</h1>
        <div className={"board"} id="content">
          {
            //@ts-ignore
            products ? (
              products.map((product: IProduct) => (
                <Link
                  to={`/product/${product.id}`}
                  className={"board-element"}
                  key={product.id}
                >
                  <div className={"board-element_name"}>{product.title}</div>
                  <div className={"board-element__img-container"}>
                    <img
                      className={"board-element_img"}
                      src={
                        this.state.imageDict
                          ? this.state.imageDict[product.id]
                          : noimage
                      }
                      alt={product.title}
                    />
                  </div>
                  <div className={"board-element_info"}>
                    <div>
                      от {getLowesPrices(product)}{" "}
                      {product.is_squared_calculator ? (
                        <>
                          {" "}
                          руб/м<sup>2</sup>
                        </>
                      ) : (
                        "руб/шт"
                      )}
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <h2>Загрузка данных..</h2>
            )
          }
        </div>
      </div>
    );
  }
}
