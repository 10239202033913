import React from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import { BASEURL } from "../../config";
import { fixUrl, getContacts } from "../../utils/utils";

export class Footer extends React.Component {
  state: { contacts: IContacts | null } = {
    contacts: null,
  };

  componentDidMount() {
    fetch(`${BASEURL}/api/contacts/`)
      .then((response) => response.json())
      .then((result) => getContacts(result))
      .then((result) => this.setState({ ...this.state, contacts: result }))
      .catch((e) => console.log(e));
  }

  render() {
    return (
      <div className={"footer"}>
        <span>
          <Link to="/">ООО "Фантазия"</Link>, Уфа. Все права защищены.{" "}
          <a href={fixUrl(this.state.contacts?.pricelist)}>
            Скачать Прайслист{" "}
          </a>
        </span>
      </div>
    );
  }
}
