import React from "react";
import "./styles.scss";
import image from "../../images/noimage.gif";
import { BASEURL } from "../../config";
import { isNull } from "util";
import {
  getProductsView,
  getProductById,
  IProduct,
  IProductElement,
  Colors,
  getProductImages,
  IImage,
  colorsToRussian,
} from "../../utils/utils";
import { Calculator } from "./components/Calculator";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ImageBlock } from "./components/ImageBlock";

interface IState {
  product: IProduct | null;
  imageList: IImage[] | null;
  square: number;
  price: number;
  count: number;
  borders: number;
  colors: Colors[];
  isOrdered: boolean;
}
interface IProductPageProps {
  match?: {
    params: {
      id: number;
    };
  };
  product?: IProduct;
  handleSetLocalStorage: (key: string, value: string) => void;
}

export class ProductPage extends React.Component<IProductPageProps> {
  state: IState = {
    product: null,
    imageList: null,
    square: 1,
    price: 0,
    count: 0,
    borders: 0,
    colors: [],
    isOrdered: false,
  };

  componentDidMount() {
    if (this.state.product) {
      //@ts-ignore
      window.prerenderReady = true;
    }
  }

  render() {
    const { product } = this.state;
    if (!product) {
      this.loadData();
      return <h3>Загрузка данных ...</h3>;
    }
    return (
      <div className={"product-page"}>
        <Helmet>
          <title>
            {product.is_squared_calculator ? "Тротуарная плитка " : ""}
            {product.title} купить, калькулятор цен | ООО "Фантазия"
          </title>
          <meta
            name="description"
            content={`Купить ${
              product.is_squared_calculator ? "тротурную плитку " : ""
            }${product.title}
            ${
              product.elements.length === 1 ? product.elements[0].size : ""
            } в Уфе от производителя. Цена от ${product.price.gray}рублей за${
              product.is_squared_calculator ? "квадратный метр" : "штуку"
            } 
            Калькулятор цен онлайн`}
          />
        </Helmet>
        <div className={"product-info"}>
          <h1 className={"product-info_name"}>{product.title}</h1>
          <div className={"product-body"}>
            <ImageBlock imageList={this.state.imageList} />
            <div className={"product-body_props"}>
              <h2>Характеристики</h2>
              {product.elements.length === 1 &&
                product.elements[0].size !== "0" && (
                  <div className={"key-value"}>
                    <span className={"key"}>Размеры: </span>
                    <span className={"value"}>{product.elements[0].size}</span>
                  </div>
                )}
              {product.is_squared_calculator && (
                <div className={"key-value"}>
                  <span className={"key"}>
                    В 1 м<sup>2</sup>:<br />
                  </span>
                  {product.elements.length === 1 ? (
                    <span className={"value-block"}>
                      <div>
                        <span className={"value"}>
                          {product.elements[0].count_in_metr} шт
                        </span>
                      </div>
                    </span>
                  ) : (
                    <span className={"value-block"}>
                      {product.elements.map((prod) => (
                        <div key={prod.title}>
                          {prod.title}:{" "}
                          <span className={"value"}>
                            {prod.count_in_metr} шт
                          </span>
                        </div>
                      ))}
                    </span>
                  )}
                </div>
              )}

              <div className={"key-value"}>
                <span className={"key"}>Вес: </span>
                {product.elements.length === 1 ? (
                  <span className={"value-block"}>
                    <div>
                      <span className={"value"}>
                        {product.elements[0].weight} кг
                      </span>
                    </div>
                  </span>
                ) : (
                  <span className={"value-block"}>
                    {product.elements.map((prod) => (
                      <div key={prod.title}>
                        {prod.title}:{" "}
                        <span className={"value"}>{prod.weight} кг</span>
                      </div>
                    ))}
                  </span>
                )}
              </div>

              <div className={"key-value"}>
                <span className={"key"}>Цена: </span>
                <span className={"value"}>{this.state.price} руб</span>
              </div>
            </div>
          </div>
        </div>

        <Calculator
          product={product}
          square={this.state.square}
          handleColor={this.handleColor}
          handleSquare={this.handleSquare}
          is_squared={product.is_squared_calculator}
          price={this.state.price * this.state.square}
        />
        {this.state.isOrdered ? (
          <Link to={"/basket"} className={"add-to-basket-button"}>
            Перейти к корзине
          </Link>
        ) : (
          <div
            className={"add-to-basket-button"}
            onClick={() => this.handleBasket()}
          >
            Добавить в корзину
          </div>
        )}
      </div>
    );
  }

  private loadData() {
    //@ts-ignore
    window.prerenderReady = false;
    //@ts-ignore
    const id = this.props.match.params.id;
    fetch(`${BASEURL}/api/components/`)
      .then((response) => response.json())
      .then((result) => getProductsView(result))
      .then((result) => getProductById(result, id))
      .then((result) => this.setState({ product: result }))
      .then(() => this.setColors())
      .catch((e) => console.log(e));

    getProductImages(id).then((imageList: IImage[]) => {
      this.setState({ imageList });
    });
  }

  private setColors() {
    let colors: Colors[] = [];
    if (!this.state.product) return;
    let length = this.state.product.elements.length;
    while (length > 0) {
      colors.push("gray");
      length--;
    }
    const price = this.calculatePrice(colors);
    this.setState({ ...this.state, colors, price });
  }

  private handleBasket() {
    if (!this.state.product) {
      console.log("Ошибка, нет продукта");
    } else {
      const product: IProduct = this.state.product;
      let weight: number = 0;
      if (product.is_squared_calculator) {
        product.elements.forEach((prod: IProductElement) => {
          weight += prod.weight;
        });
      } else {
        product.elements.forEach((prod: IProductElement) => {
          weight += prod.weight * prod.count_in_metr;
        });
      }
      const basketElement: IBasketElement = {
        title: product.title,
        image: this.state.imageList ? this.state.imageList[0].src : image,
        price: this.state.price,
        square: this.state.square,
        colors: this.state.colors.map((c) => colorsToRussian[c]),
        weight_per_square: weight,
        is_squared: product.is_squared_calculator,
      };
      let json;
      const fromStorage = localStorage.getItem("basket");
      if (isNull(fromStorage)) {
        json = JSON.stringify([basketElement]);
      } else {
        json = JSON.parse(fromStorage);
        let newElement = true;
        for (let i = 0; i < json.length; ++i) {
          const item: IBasketElement = json[i];
          if (
            item.title === basketElement.title &&
            this.equalColors(item.colors, basketElement.colors)
          ) {
            item.square += basketElement.square;
            newElement = false;
          }
        }
        if (newElement) {
          json.push(basketElement);
        }
        json = JSON.stringify(json);
      }
      this.props.handleSetLocalStorage("basket", json);
      this.setState({ ...this.state, isOrdered: true });
    }
  }

  private equalColors(a: string | string[], b: string | string[]) {
    if (typeof a == "string") {
      return a === b;
    } else {
      return a[0] === b[0] && a[1] === b[1];
    }
  }

  private calculatePrice(colors: Colors[]) {
    const { product } = this.state;
    if (!product) return null;
    let price = 0;
    for (let i in product.elements) {
      const component: IProductElement = product.elements[i];
      const productPrice = product.price[colors[i]];
      if (product.price[colors[i]] == 0) return 0;
      price += productPrice * component.coef;
    }
    price = Math.ceil(price / 10) * 10;
    return price;
  }

  private handleColor = (colors: Colors[]) => {
    const price = this.calculatePrice(colors);
    this.setState({ ...this.state, colors, price, isOrdered: false });
  };

  private handleSquare = (event: any) => {
    const value = event.target.valueAsNumber;
    const price = this.calculatePrice(this.state.colors);
    this.setState({
      ...this.state,
      square: value,
      price,
      isOrdered: false,
    });
  };
}
