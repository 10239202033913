import React from "react";
import "./styles.scss";
import gazel from "../../images/gazel.png";
import { Helmet } from "react-helmet";

const cls = "delivery";

export class Delivery extends React.Component {
  render() {
    return (
      <div className={`${cls}-page`}>
        <Helmet>
          <title>Тротуарной плитка с доставкой по Уфе | ООО "Фантазия</title>
          <meta name="description" content={`Доставка троутрной плитки по Уфе и до близлежащих районов Башкирии. 
            Доставим на своем а/м Газель или поможем вам организовать доставку с помощью манипулятора`} />
        </Helmet>
        <h1>Доставка тротуарной плитки</h1>
        <div className={`${cls}-content`}>
          <p>
          <img src={gazel} alt="Доставка тротуарной плитки по Уфе"/>
            Если Вам требуется доставка, мы можем доставить нашу продукцию на
            своем а/м Газель. Стоимость доставки будет зависеть от расстояния до
            Вашего адреса. Грузоподъемность Газели 1,5 тонны. Вы можете увидеть
            примерный вес Вашего заказа в корзине. При достаточно большом
            заказе, превышающем возможности Газели, целесообразнее будет
            организовать доставку с помощью манипулятора. Если это будет
            необходимо, мы можем Вам помочь с этим вопросом.
              
          </p>
        </div>
      </div>
    );
  }
}
