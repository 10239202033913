import React from "react";
import "./styles.scss";

import image from "../../images/noimage.gif";
import { IImage, getGaleryImages } from "../../utils/utils";
import { Helmet } from "react-helmet";

interface IState {
  imageList?: IImage[];
  showModal: boolean;
  activeIndex: number;
}

const cls = "galery-page";

export class Galery extends React.Component {
  state: IState = {
    showModal: false,
    activeIndex: 0,
  };
  render() {
    if (!this.state.imageList) {
      this.loadData();
    }
    return (
      <>
        <Helmet>
          <title>
            Галерея | ООО "Фантазия"
          </title>
        </Helmet>
        <div className={cls}>
          <div className={`${cls}__image-container`}>
            {this.state.imageList?.map((image, index) => {
              return (
                <img
                  className={`${cls}__image-element`}
                  src={image.src}
                  onClick={() => this.clickHandler(index)}
                />
              );
            })}
          </div>
        </div>
        {this.state.showModal && (
          <>
            <div
              className={`${cls}__modal`}
              onClick={() => {
                this.setState({ showModal: false });
              }}
            ></div>
            <img
              className={`${cls}__modal-inner`}
              src={
                this.state.imageList
                  ? this.state.imageList[this.state.activeIndex].src
                  : image
              }
            />
          </>
        )}
      </>
    );
  }

  private clickHandler(activeIndex: number) {
    this.setState({ activeIndex, showModal: true });
  }

  private loadData() {
    getGaleryImages().then((imageList: IImage[]) => {
      this.setState({ imageList });
    });
  }
}
