import React from "react";
import "./styles.scss";
import main_1 from "../../images/main_1.jpg";
import main_2 from "../../images/main_2.jpg";
import stand from "../../images/stand.jpg";
import { Helmet } from "react-helmet";

const cls = "main";

export class MainPage extends React.Component {
  render() {
    return (
      <div className={`${cls}-page`}>
        <Helmet>
          <title>
            Тротуарная плитка в Уфе от производителя | ООО "Фантазия
          </title>
          <meta
            name="description"
            content={`Качественная тротуарная плитка в Уфе. ООО "Фантазия" производство 
            и продажа тротуарной плитки. Цена от 300 рублей за квадратный метр. У нас вы можете воспользоваться онлайн 
            калькулятором цен и оформить заказ. С доставкой или самовывозом`}
          />
        </Helmet>
        <div className={`${cls}-page__block`}>
          <h2>Стенд с нашей продукцией в Чишмах</h2>
          <div className={`${cls}-page__block-content`}>
            <img src={stand} alt="Стенд с тротуарной плиткой в Чишмах" />
            <p>
              Наш стенд с тротуарной плиткой находится в Чишмах, по адресу улица
              Революционная 86, рядом с магазином Находка. Можно посмотреть и
              оценить многие наши образцы
            </p>
          </div>
        </div>
        <h2>Тротуарная плитка. Производство и продажа.</h2>
        <div className={`${cls}-page__block`}>
          <img src={main_1} alt="Тротуарная плитка в Уфе" />
          <h3>ООО "Фантазия" предлагает широкий ассортимент</h3>
          <p>строительных материалов для внутренней и наружной отделки помещений и
          благоустройства прилегающих территорий. В нашем каталоге можно найти
          тротуарную и облицовочную плитку, вазы, лестницы и многое другое. Мы
          производим продукцию как с применением обычного метода вибролитья, так
          и с применением технологии СИСТРОМ. Эта технология известна во многих
          странах и везде неизменно пользуется успехом. Материалы, изготовленные
          по технологии СИСТРОМ, имеют очень высокие эксплуатационные
          характеристики, не боятся морозов, прочны. При этом они имеют крайне
          привлекательный вид, который не теряется долгое время. Часто
          продукцию, произведенную по данной технологии, называют "Мрамор из
          бетона".</p>
        </div>

        <div className={`${cls}-page__block`}>
          <img src={main_2} alt="производство тротуарной плитки" />
          <h3>Где может применяться наша продукция?</h3>
          <p>Да практически повсеместно. Напольное покрытие торговых павильонов и
          офисов, выполненное из нашей плитки, долгие годы будет выглядеть как
          новое, не смотря на тысячи ног, ежедневно истирающие пол. Красивые
          вазы, установленные перед парадным входом, даже после затяжных ливней
          и нещадных ветров не перестанут сверкать на солнце, привлекая
          посетителей. Балюстрады придадут торжественность любому помещению,
          привлекут взор гостей на веранде коттеджа и не один год будут радовать
          глаз и выгодно выделять ваше жилище на фоне соседей. Конечно, такой
          плиткой не следует выкладывать пол в жилых помещениях. Ведь это,
          все-таки, бетон. Да и с домашними нагрузками вполне справится
          керамическая плитка, которая для этого и предназначена. А вот для
          наружных работ наша плитка подходит идеально. Она выдерживает
          многократные циклы заморозки/оттаивания не ломаясь и не трескаясь.
          Высокие потребительские качества нашей продукции обеспечиваются за
          счет тщательного отбора материала на входе, неукоснительного
          соблюдения технологии производства и постоянного контроля на всех
          стадиях. При этом цены на{" "}
          <a href={"/products"}>
            <b>продукцию</b>
          </a>{" "}
          являются вполне доступными.</p>
        </div>

        <p>Звоните: +79173465860.</p>
      </div>
    );
  }
}
