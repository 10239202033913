import React from "react";
import "./styles.scss";
import { BasketSmall } from "../BasketSmall";
import { Link } from "react-router-dom";
import whatsapp from "../../images/Whatsapp.png";
import { BASEURL } from "../../config";
import { getContacts } from "../../utils/utils";
import { MOBILE_MENU } from "../../icons";

interface IHeaderProps {
  basket: string | null;
}
interface IState {
  contacts: IContacts | null;
  showMenu: boolean;
}

export class Header extends React.Component<IHeaderProps> {
  state: IState = {
    contacts: null,
    showMenu: false,
  };

  componentDidMount() {
    fetch(`${BASEURL}/api/contacts/`)
      .then((response) => response.json())
      .then((result) => getContacts(result))
      .then((result) => this.setState({ ...this.state, contacts: result }))
      .catch((e) => console.log(e));
  }

  render() {
    const basket = this.props.basket;
    const json = basket && JSON.parse(basket);
    const positions = json ? json.length : 0;
    const { contacts } = this.state;
    return (
      <div className={"header"}>
        <div className={"header-content"}>
          <div className="header-content__left"></div>
          <div className="header-content__middle">
            <h2>ООО "Фантазия"</h2>
            <div className={"header-contacts"}>
              <div className={"header-contacts__row"}>
                <span className={"header-contacts__row-number"}>
                  {contacts?.phone_1 || "+79173465860"}
                </span>
                <a href={`https://wa.me/${contacts?.phone_1}`}>
                  <img src={whatsapp} alt="whatsapp" className={"whatsapp"} />
                </a>
              </div>
              <div className={"header-contacts__row"}>
                <span className={"header-contacts__row-number"}>
                  {contacts?.phone_2 || "+79632354874"}
                </span>
              </div>
              <div className={"header-contacts__row"}>
                <span className={"header-contacts__row-number"}>
                  {contacts?.phone_3 || "+7(347)2940039"}
                </span>
              </div>
            </div>
          </div>
          <div className="header-content__right">
            <BasketSmall positions={positions} />
          </div>
        </div>
        {this.getMenu()}
      </div>
    );
  }

  private getMenu() {
    const isSmallScreen = window.innerWidth < 800;
    if (isSmallScreen) {
      return (
        <div
          className="header-menu-wrapper"
          onClick={() => this.setState({ showMenu: !this.state.showMenu })}
        >
          {MOBILE_MENU}
          {this.state.showMenu && this.getMenuButtons("header-menu")}
        </div>
      );
    } else {
      return this.getMenuButtons("header-menu");
    }
  }

  private getMenuButtons(className?: string) {
    return (
      <div className={className}>
        <Link to={"/"} className={"header-menu__element"}>
          Главная
        </Link>
        <Link to={"/products#content"} className={"header-menu__element"}>
          Продукция
        </Link>
        <Link to={"/galery"} className={"header-menu__element"}>
          Галерея
        </Link>
        <Link to={"/contacts"} className={"header-menu__element"}>
          Контакты
        </Link>
        <Link to={"/basket"} className={"header-menu__element"}>
          Корзина
        </Link>
        <Link to={"/delivery"} className={"header-menu__element"}>
          Доставка
        </Link>
      </div>
    );
  }
}
