import React from "react";
import "./styles.scss";
import { Helmet } from "react-helmet";
import { getPageById, PagesAndPanel } from "../../utils/utils";

interface IState {
  pagesAndPanel: PagesAndPanel | null;
}

interface PagesProps {
  match?: {
    params: {
      id: number;
    };
  };
}

export class Pages extends React.Component<PagesProps> {
  state: IState = {
    pagesAndPanel: null,
  };

  componentDidMount() {
    const id = this.props.match?.params.id as number;
    getPageById(id).then(pagesAndPanel => this.setState({pagesAndPanel}))
  }

  render() {
    const page_content = this.state.pagesAndPanel?.page_content;
    if (!page_content) return null;
    return (
      <>
        <Helmet>
          <title>{this.state.pagesAndPanel?.title}  | ООО "Фантазия</title>
        </Helmet>
        <div dangerouslySetInnerHTML={{ __html: page_content }}></div>
      </>
    );
  }
}
