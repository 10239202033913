import React from "react";
import "./styles.scss";
import whatsapp from "../../images/Whatsapp.png";
import { Link } from "react-router-dom";
import {
  getContacts,
  getPagesAndPanel,
  PagesAndPanel,
  fixUrl,
} from "../../utils/utils";
import { BASEURL } from "../../config";

interface IState {
  contacts: IContacts | null;
  pagesAndPanel: PagesAndPanel[] | null;
}

export class LeftMenu extends React.Component {
  state: IState = {
    contacts: null,
    pagesAndPanel: null,
  };

  componentDidMount() {
    fetch(`${BASEURL}/api/contacts/`)
      .then((response) => response.json())
      .then((result) => getContacts(result))
      .then((result) => this.setState({ ...this.state, contacts: result }))
      .catch((e) => console.log(e));
    // fetch(`${BASEURL}/api/news/`)
    //   .then((response) => response.json())
    //   .then((result) => getNews(result))
    //   .then((result) => filterNews(result as INews[]))
    //   .then((result) => this.setState({ ...this.state, news: result }))
    //   .catch((e) => console.log(e));
    getPagesAndPanel().then((pagesAndPanel) =>
      this.setState({ pagesAndPanel })
    );
  }

  render() {
    const { contacts } = this.state;
    return (
      <div className={"left-menu"}>
        <div className={"left-menu__block"}>
          <Link
            to={"/products/category/0"}
            className={"left-menu__name left-menu__name--clickable"}
          >
            Тротуарная плитка, бордюры, сливы
          </Link>
          <Link
            to={"/products/category/1"}
            className={"left-menu__name left-menu__name--clickable"}
          >
            Сайдинг, дикий камень
          </Link>
        </div>
        <div className={"left-menu__block"}>
          <div className={"left-menu__block-title"}>Наши контакты</div>
          <div
            className={"left-menu__block-content"}
            style={{ lineHeight: "35px" }}
          >
            <div>
              <span>{contacts?.phone_1 || "+79173465860"}</span>
              <a
                href={`https://wa.me/${contacts?.phone_1}`}
                target="blank"
                rel="nofollow"
              >
                <img src={whatsapp} alt="whatsapp" className={"whatsapp"} />
              </a>
            </div>
            <div>
              <span>{contacts?.phone_2 || "+79632354874"}</span>
            </div>
            <div>
              <span>{contacts?.phone_3 || "+7(347)2940039"}</span>
            </div>
            <div>
              <span>{contacts?.email || "info@plitka-ufa.ru"}</span>
            </div>
          </div>
        </div>
        <div className={"left-menu__block"}>
          <div className={"left-menu__block-title--little"}>
            Скачать прайслист:
          </div>
          <div
            className={"left-menu__block-content"}
            style={{ textDecoration: "underline" }}
          >
            <a href={fixUrl(contacts?.pricelist)}>{contacts?.pricelist_title}</a>
            <br />
          </div>
        </div>
        {this.state.pagesAndPanel?.map((item: PagesAndPanel) => {
          if (item.is_panel && item.is_active)
            return (
              <Link
                to={`/page/${item.id}`}
                key={item.title}
                className="left-menu__block"
                dangerouslySetInnerHTML={{ __html: item.panel_content }}
              ></Link>
            );
        })}
      </div>
    );
  }
}
