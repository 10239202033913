import React from "react";
import "./ImageBlock.scss";
import { IImage } from "../../../utils/utils";
import image from "../../../images/noimage.gif";

interface ImageBlockProps {
  imageList: IImage[] | null;
}

interface ImageBlockState {
  activeIndex: number;
}

const CLS = "image-block";
const BIG_IMAGE_CLS = `${CLS}__big-image`;
const IMAGES_LIST_CLS = `${CLS}__list`;
const IMAGES_LIST_INNER_CLS = `${CLS}-inner-list`;
const IMAGES_LIST_INNER_ITEM_CLS = `${IMAGES_LIST_INNER_CLS}__item`;

export class ImageBlock extends React.Component<ImageBlockProps> {
  state: ImageBlockState = {
    activeIndex: 0,
  };
  render() {
    const showList = !!(this.props.imageList?.length && this.props.imageList?.length > 1);
    return (
      <div className={CLS}>
        <img
          className={BIG_IMAGE_CLS}
          src={
            this.props.imageList && this.props.imageList[this.state.activeIndex]
              ? this.props.imageList[this.state.activeIndex].src
              : image
          }
          alt={"title"}
        />
        {showList && (
          <div className={IMAGES_LIST_CLS}>
            <span className={IMAGES_LIST_INNER_CLS}>
              {this.props.imageList?.map((item, index) => (
                <img
                  onClick={() => this.clickHandler(index)}
                  className={`${IMAGES_LIST_INNER_ITEM_CLS} ${
                    index === this.state.activeIndex
                      ? `${IMAGES_LIST_INNER_ITEM_CLS}--active`
                      : ""
                  }`}
                  src={item.src}
                  key={index}
                />
              ))}
            </span>
          </div>
        )}
      </div>
    );
  }

  private clickHandler(activeIndex: number) {
    this.setState({ activeIndex });
  }
}
