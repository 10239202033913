import React from "react";
import "./styles.scss";
import { BASKET } from "../../icons";
import { Link } from "react-router-dom";

interface IBasketSmallProps {
  positions: number;
}

export class BasketSmall extends React.Component<IBasketSmallProps> {
  render() {
    return (
      <Link to={"/basket"} className={"basket-small"}>
        <div className="basket-small__icon">
          {BASKET}
          <div className="basket-small__icon-count">{this.props.positions}</div>
        </div>
      </Link>
    );
  }
}

// export default Header;
