import React from "react";
import "./styles.scss";
import { colorTranslate } from "../../utils/utils";
import { BASEURL } from "../../config";
import { Helmet } from "react-helmet";

interface IState {
  products: IBasketElement[] | null;
  name: string;
  phone: string;
  price: number;
}

interface IBasketElementProps {
  product: IBasketElement;
  onDelete: () => void;
  onUpdateSquare: (value: number) => void;
}

function BasketElement(props: IBasketElementProps) {
  const cls = "basket-element";
  const { product, onDelete, onUpdateSquare } = props;
  const [square, setSquare] = React.useState(product.square);
  return (
    <div className={`${cls}`}>
      <img className={`${cls}__img`} src={product.image} alt={product.title} />

      <div className={`${cls}-data`}>
        <div className={`${cls}-data-header ${cls}-common`}>
          <div>
            <b>{product.title}</b>
          </div>
          <div className={`${cls}-data-header__colors`}>
            {product.colors.map((color: string, index) => (
              <span>
                {index > 0 && "+"}
                {color}
              </span>
            ))}
          </div>
        </div>

        <div className={`${cls}-data__values-container`}>
          <div className={`${cls}-data__values ${cls}-common`}>
            <div>
              <span className={`${cls}-important-values`}>
                <input
                  className={`${cls}-input-square`}
                  type="number"
                  value={square}
                  min="0"
                  onChange={(e) => {
                    setSquare(e.target.valueAsNumber);
                    onUpdateSquare(e.target.valueAsNumber);
                  }}
                />
              </span>{" "}
              {product.is_squared ? (
                <>
                  м<sup>2</sup>
                </>
              ) : (
                "шт"
              )}
            </div>
            <div>
              <span className={`${cls}-important-values`}>
                {Math.round(product.weight_per_square * square)}
              </span>{" "}
              кг
            </div>
          </div>

          <div className={`${cls}-data__price ${cls}-common`}>
            <span className={`${cls}-important-values`}>
              {Math.ceil((product.price * square) / 10) * 10}
            </span>{" "}
            руб
          </div>
        </div>
      </div>

      <div className={`${cls}-data__delete-btn ${cls}-common`}>
        <div onClick={onDelete}>X</div>
      </div>
    </div>
  );
}

interface IBasketPageProps {
  handleSetLocalStorage: (key: string, value: string) => void;
}

export class BasketPage extends React.Component<IBasketPageProps> {
  state: IState = {
    products: null,
    name: "",
    phone: "",
    price: 0,
  };

  componentWillMount() {
    this.setBasket();
  }

  onDelete(id: number) {
    const fromStorage = localStorage.getItem("basket");
    if (fromStorage == null) {
      this.setState({ ...this.state, products: null });
    } else {
      let products = JSON.parse(fromStorage) as IBasketElement[];
      products.splice(id, 1);
      const jsonString = JSON.stringify(products);
      this.props.handleSetLocalStorage("basket", jsonString);
      this.setState({ ...this.state, products });
    }
  }

  getOrderText(): string {
    let text: string = "";
    this.state.products?.forEach((prod) => {
      let itemText = `${prod.title}  Цвет: ${prod.colors}  ${
        prod.is_squared ? "Площадь:" : "Количество:"
      } ${prod.square} ${prod.is_squared ? "кв.м." : "шт"}  Цена: ${
        prod.price
      }руб\n`;
      text += itemText;
    });
    return text;
  }

  clickHandler = () => {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("phone", this.state.phone);
    formData.append("text", this.getOrderText());
    formData.append("price", String(this.state.price));
    fetch(`${BASEURL}/api/orders/`, {
      method: "post",
      body: formData,
    });
    this.setState({ ...this.state, name: "", phone: "" });
  };

  nameHandler = (e: any) => {
    this.setState({ ...this.state, name: e.target.value });
  };

  phoneHandler = (e: any) => {
    this.setState({ ...this.state, phone: e.target.value });
  };

  render() {
    const products = this.state.products;
    if (!products || products.length === 0) {
      return <h1>Ваша корзина пуста</h1>;
    }
    let sum = 0;
    let weight = 0;
    for (let i = 0; i < products.length; ++i) {
      sum += products[i].price * products[i].square;
      weight += Math.round(products[i].weight_per_square * products[i].square);
    }
    sum !== this.state.price && this.setState({ ...this.state, price: sum });
    return (
      <div className={"basket-page"}>
        <Helmet>
          <title>
            Корзина вашего заказа, оформление заказа онлайн | ООО "Фантазия
          </title>
        </Helmet>
        <h1>Ваша корзина</h1>
        {products.map((product: IBasketElement, id: number) => (
          <BasketElement
            product={product}
            onDelete={() => this.onDelete(id)}
            onUpdateSquare={(value: number) => this.updateProducts(id, value)}
            key={id + " " + product.colors}
          />
        ))}
        <div className={"basket-sum"}>
          <span>Итого:</span>
          <span>
            <span>{weight} кг</span>
            <br />
            <span>{this.state.price} руб</span>
          </span>
        </div>
        <div className="order">
          <div>
            Оставьте заявку и мы вам перезвоним. Или свяжитесь с нам
            самостоятельно
          </div>
          <form className="order-form">
            <label>
              <div>Ваше имя: </div>
              <input
                type="text"
                onChange={this.nameHandler}
                value={this.state.name}
              />
            </label>
            <label>
              <div>Телефон: </div>
              <input
                type="tel"
                onChange={this.phoneHandler}
                value={this.state.phone}
              />
            </label>
            <div className="order-btn" onClick={this.clickHandler}>
              Отправить
            </div>
          </form>
        </div>
      </div>
    );
  }

  private setBasket() {
    const fromStorage = localStorage.getItem("basket");
    if (fromStorage == null) {
      this.setState({ ...this.state, products: null });
    } else {
      const products = JSON.parse(fromStorage) as IBasketElement[];
      this.setState({ ...this.state, products });
    }
  }

  private updateProducts(id: number, square: number) {
    const fromStorage = localStorage.getItem("basket");
    if (fromStorage == null) {
      this.setState({ ...this.state, products: null });
    } else {
      const products = JSON.parse(fromStorage) as IBasketElement[];
      products[id].square = square;
      this.setState({ ...this.state, products });
      localStorage.setItem("basket", JSON.stringify(products));
    }
  }
}
