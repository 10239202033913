import React from "react";
import "./App.css";
import { Header } from "./components/Header";
import { LeftMenu } from "./components/LeftMenu";
import { ProductBoard } from "./components/ProductBoard";
import { ProductPage } from "./components/ProductPage";
import { Footer } from "./components/Footer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { BasketPage } from "./components/BasketPage";
import { Contacts } from "./components/Contacts";
import { Delivery } from "./components/Delivery";
import { Galery } from "./components/Galery";
import { MainPage } from "./components/MainPage";
import { Pages } from "./components/Pages";

import { YMInitializer } from "react-yandex-metrika";
import { NotFoundPage } from "./components/NotFoundPage/NotFoundPage";

class App extends React.Component {
  state: { storage: string | null } = { storage: null };

  render() {
    const handleSetLocalStorage = (key: string, value: string) => {
      localStorage.setItem(key, value);
      this.setState({ storage: localStorage.getItem(key) });
    };
    const storage = localStorage.getItem("basket");
    if (storage !== this.state.storage) {
      this.setState({ storage: storage });
    }

    return (
      <BrowserRouter>
        <div className={"wrapper"}>
          <YMInitializer accounts={[73940524]} />
          <Header basket={this.state.storage} />
          <div className={"body-wrapper"}>
            <LeftMenu />
            <div className={"container"}>
              <Switch>
                <Route path="/" exact component={MainPage} />
                <Route path="/products" exact component={ProductBoard} />
                <Route path="/products/category/:id" component={ProductBoard} />
                <Route path="/page/:id" component={Pages} />
                <Route path="/contacts" component={Contacts} />
                <Route path="/delivery" component={Delivery} />
                <Route path="/galery" component={Galery} />
                <Route
                  path="/product/:id"
                  render={(props) => (
                    <ProductPage
                      handleSetLocalStorage={handleSetLocalStorage}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/basket"
                  render={(props) => (
                    <BasketPage
                      handleSetLocalStorage={handleSetLocalStorage}
                      {...props}
                    />
                  )}
                />
                <Route path="/" component={NotFoundPage} />
              </Switch>
            </div>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
